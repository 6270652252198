import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Container, Row, Button } from "react-bootstrap";
import { AiOutlineDownload } from "react-icons/ai";
import Particle from "../Particle";
import pdf from "../../Assets/../Assets/Profile.pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`;

function ResumeNew() {
  // Track both window width and container width for more precise scaling
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [containerWidth, setContainerWidth] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const containerRef = React.useRef(null);

  // Calculate the appropriate scale based on container width
  const getScale = () => {
    if (!containerWidth) return 1;
    
    // For mobile devices (width <= 768px)
    if (windowWidth <= 768) {
      // Calculate scale to fit container width with some padding
      const targetWidth = containerWidth - 32; // 16px padding on each side
      return targetWidth / 612; // 612 is the standard PDF width in points
    }
    
    // For larger screens
    return windowWidth > 1200 ? 1.75 : 1.2;
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
    };

    // Initial measurement
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div>
      <Container fluid className="resume-section">
        <Particle />
        <Row className="justify-content-center mb-4">
          <Button
            variant="primary"
            href={pdf}
            target="_blank"
            className="w-auto"
          >
            <AiOutlineDownload />
            &nbsp;Download CV
          </Button>
        </Row>

        <Row className="resume justify-content-center">
          <div 
            ref={containerRef}
            className="pdf-container"
            style={{
              width: '100%',
              maxWidth: '100vw',
              overflow: 'hidden',
              padding: '0 16px'
            }}
          >
            <Document
              file={pdf}
              onLoadSuccess={({ numPages }) => setNumPages(numPages)}
              className="d-flex flex-column align-items-center"
            >
              {Array.from(new Array(numPages), (_, index) => (
                <div 
                  key={`page_${index + 1}`} 
                  className="mb-4"
                  style={{
                    maxWidth: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <Page
                    pageNumber={index + 1}
                    scale={getScale()}
                    renderTextLayer={true}
                    renderAnnotationLayer={true}
                    className="pdf-page"
                  />
                </div>
              ))}
            </Document>
          </div>
        </Row>

        <Row className="justify-content-center mt-4">
          <Button
            variant="primary"
            href={pdf}
            target="_blank"
            className="w-auto"
          >
            <AiOutlineDownload />
            &nbsp;Download CV
          </Button>
        </Row>
      </Container>
    </div>
  );
}

export default ResumeNew;